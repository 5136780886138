<template>
  <div class="w-full h-full relative text-center m-0 p-0">
    <div class="fixed w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center overflow-y-auto">
      <div class="relative w-full text-center pt-36 sm:pt-[10%] md:w-2/3 md:inline-block">
        <span class="text-4xl font-bold block">Admin Center</span>
        <div v-if="stats.permissions.internalDispo || stats.permissions.externalDispo" class="dashboardtile" onclick="window.location='/admin/jobs'">
          <i class="fa fa-list fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Aufträge</span>
        </div>
        <div v-if="stats.permissions.admin" class="dashboardtile" onclick="window.location='/admin/planning'">
          <div class="center">
            <i class="fa fa-file-circle-plus fa-2x pt-2" /><br />
            <span class="inline-block pb-2 align-middle text-lg w-full break-word hyphens-auto">Auftrags-planung</span>
          </div>
        </div>
        <div v-if="stats.permissions.internalDispo || stats.permissions.externalDispo" class="dashboardtile" onclick="window.location='/admin/plan'">
          <div class="center">
            <i class="fa fa-timeline fa-2x pt-2" /><br />
            <span class="inline-block pb-2 align-middle text-lg hyphens-auto">Schicht-planung</span>
          </div>
        </div>
        <div v-if="stats.permissions && stats.permissions.internalDispo" class="dashboardtile" onclick="window.location='/tasks'">
          <i class="fa fa-list-check fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Aufgaben</span>
          <span  v-if="stats.tasks > 0" class="bg-red-500 rounded-full absolute p-1 -top-2 -right-2">{{ stats.tasks }}</span>
        </div>
        <div v-if="stats.permissions.admin" class="dashboardtile" onclick="window.location='/admin/adminusers'">
          <i class="fa fa-users fa-2x" /><br />
          <span class="inline-block pb-2 align-middle text-lg w-full break-word">Nutzer-verwaltung</span>
        </div>
        <div v-if="stats.permissions.admin" class="dashboardtile" onclick="window.location='/admin/employees'">
          <i class="fa fa-users-rectangle fa-2x" /><br />
          <span class="inline-block align-middle text-lg  w-full break-word">Mitarbeiter-verwaltung</span>
        </div>
        <div v-if="stats.permissions.internalDispo" class="dashboardtile" onclick="window.location='/admin/messages'">
          <i class="far fa-comment fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Nachrichten</span>
        </div>
        <div v-if="stats.permissions.standby" class="dashboardtile" onclick="window.location='/admin/checkin'">
          <i class="fas fa-clipboard-check fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Check-Ins</span>
        </div>
        <div v-if="stats.permissions.documents" class="dashboardtile" onclick="window.location='/admin/documents'">
          <i class="fas fa-folder-open fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Schicht-Dokumente</span>
        </div>
        <br />
        <button v-if="stats.type=='internal'" class="mb-16 mt-10 border-caspyellow  text-black bg-caspyellow rounded-lg w-28 xl:w-56 xs:w-72 py-2 border-2 font-bold xl:m-10" onclick="window.location='/'">
          Zurück
        </button>
        <button class="mb-16 mt-10 border-black  text-black bg-red-500 rounded-lg w-28 xl:w-56 xs:w-72 py-2 border-2 font-bold xl:m-10" @click="logoutWeb()">
          Ausloggen
        </button>
      </div>
    </div>
    <div class="bg-gray-50 border-t border-t-gray-300 fixed bottom-0 w-full text-center xl:hidden" onclick="window.location='/settings'">
      <span class="inline-block py-2 text-iosblue font-medium">Einstellungen</span>
    </div>
  </div>
</template>
<script>
import CASP from "../../CASP";
import Toolbar from "@/components/Toolbar.vue";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      featureToggle: false,
      stats: { permissions: {} },
    };
  },
  methods: {
    logoutWeb() {
      this.cookies.remove("authtoken");
      CASP.logoutWeb((status, response) => {
        window.location="/login"
      })
    },
  },
  components: { Toolbar },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    CASP.getStats(this.cookies.get("authtoken"), (status, response) => {
      if (status == 401) {
        window.location = "/forbidden";
      } else if (status == 200) {
        this.stats = JSON.parse(response);
      }
    });
  },
};
</script>
