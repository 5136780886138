<template>
  <toolbar title="Wächter-Kontrollgang" />
  <div v-if="stats">
    <div v-if="stats.current" class="text-center">
      <span class="font-bold w-full"
        >{{ stats.current.Vertragsbeschreibung }} ({{
          stats.current.Datum_von
        }})</span
      >
    </div>
    <div v-if="stats.next" class="text-center">
      <span class="font-bold text-xl w-full"
        >{{ stats.next.Vertragsbeschreibung }} ({{
          stats.next.Datum_von
        }})</span
      >
      <div class="border p-2 text-left cursor-pointer" @click="window.location='/ewks/1'">
        <span>Rundgang 1</span>
        <div
          class="text-sm font-bold inline-block p-1 rounded ml-2 border text-yellow-800 bg-yellow-200 border-yellow-800"
        >
          1/3
        </div>
      </div>
      <div class="border p-2 text-left cursor-pointer" @click="window.location='/ewks/1'">
        <span>Rundgang 2</span>
        <div
          class="text-sm font-bold inline-block p-1 rounded ml-2 border text-red-800 bg-red-200 border-red-800"
        >
          0/3
        </div>
      </div>
      <button class="mt-2">Neuen Rundgang starten</button>
    </div>
    <div v-else>
      <button @click="window.location='/ewks/1'">Debug</button>
  </div>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar.vue";
export default {
  name: "EWKSList",
  components: {
    toolbar,
  },
  data() {
    return {
      stats: {},
      window: window,
    };
  },
  methods: {
    getStats() {
      if (localStorage && localStorage.getItem("stats")) {
        this.stats = JSON.parse(localStorage.getItem("stats"));
      }
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    this.getStats();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
