<template>
  <toolbar title="Kontrollgang" />
  <div v-show="qrscan" class="absolute w-full h-full top-0 left-0 z-10">
    <div class="text-center pt-36 w-full h-full z-10 bg-black/80">
      <video
        id="video"
        ref="video"
        class="w-96 h-96 max-h-96 inline-block"
        autoplay
      ></video><br>
    <button class="text-black bg-red-500" @click="stopQRScan()">Abbrechen</button>
    </div>
  </div>
  <div v-if="stats">
    <div class="grid grid-cols-2 font-bold">
      <span class="w-full text-center text-sm"
        >Gestartet: {{ tour.startedAt }}</span
      >
      <span class="w-full text-center text-sm"
        >Beendet: {{ tour.finishedAt }}</span
      >
    </div>
    <div class="border-t border-b border-gray-600 p-2">
      <span class="text-xl font-bold">Wegpunkt / Hinweis erfassen</span>
      <div class="grid grid-cols-2 mt-2">
        <div class="text-center">
          <button
            class="inline-block"
            @click="startQRScan()"
          >
            <i class="fas fa-qrcode fa-2x" /><span
              class="align-top mt-1 inline-block ml-2"
              >QR-Code</span
            >
          </button>
        </div>
        
        <div class="text-center">
          <button class="inline-block">
            <i class="fas fa-circle-info fa-2x" /><span
              class="align-top mt-1 inline-block ml-2"
              >Hinweis</span
            >
          </button>
        </div>
      </div>
    </div>
    <div>
      <div
        v-for="waypoint in tour.waypoints"
        :key="waypoint.name"
        class="border-y p-2 cursor-pointer relative"
      >
        <span
          class="w-full inline-block"
          @click="expandedWaypoint = waypoint"
          >{{ waypoint.name }}</span
        >
        <div class="absolute right-2 top-1">
          <div
            v-if="!waypoint.scannedAt"
            class="text-sm font-bold inline-block p-1 rounded ml-2 border text-red-800 bg-red-200 border-red-800"
          >
            Offen
          </div>
          <div
            v-else
            class="text-sm font-bold inline-block p-1 rounded ml-2 border text-green-800 bg-green-200 border-green-800"
          >
            {{ waypoint.scannedAt }}
          </div>
        </div>
        <div v-show="expandedWaypoint == waypoint">
          <textarea
            class="px-2 py-1 w-full mt-2"
            v-model="waypoint.comment"
          ></textarea>
          <div class="w-full text-right">
            <button @click="expandedWaypoint = false">
              Kommentar speichern
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center pt-2">
      <button class="text-black bg-red-500">Kontrollgang abschließen</button>
    </div>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar.vue";
export default {
  name: "Tour",
  components: {
    toolbar,
  },
  data() {
    return {
      expandedWaypoint: false,
      qrscan: false,
      window: window,
      tour: {
        startedAt: new Date().toLocaleTimeString(),
        finishedAt: "Nein",
        waypoints: [
          {
            id: "Test1",
            name: "Tür Eingang 1",
            scannedAt: new Date().toLocaleTimeString(),
            comment: "Türe war offen",
          },
          {
            id: "https://www.applyhr.de/",
            name: "Tür Hintereingang",
            scannedAt: false,
            comment: "",
          },
          {
            id: "WP-A-123",
            name: "Tor Mitarbeiter",
            scannedAt: false,
            comment: "",
          },
        ],
      },
      stats: {},
    };
  },
  methods: {
    stopQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
      }
    },
    startQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
        return;
      }
      console.log("start scan");
      window.rescanner.startQRScan(this.$refs.video, "environment");
      this.qrscan = true;
    },
    scanResult(message) {
      // find waypoint where id is message
      let waypoint = this.tour.waypoints.find((w) => w.id == message);
      if (waypoint) {
        waypoint.scannedAt = new Date().toLocaleTimeString();
        this.expandedWaypoint = waypoint;
      }else{
        alert("Falscher QR-Code");
      }
    },
    getTour() {
      if (localStorage && localStorage.getItem("tour")) {
        this.tour = JSON.parse(localStorage.getItem("tour"));
      }
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (localStorage && localStorage.getItem("stats")) {
      this.stats = JSON.parse(localStorage.getItem("stats"));
    }
    this.expandedWaypoint = this.tour.waypoints[0];
    if (!window.rescanner) {
      let vm = this;
      let rescannerLib = document.createElement("script");
      rescannerLib.setAttribute("src", "/rescanner.js");
      rescannerLib.onload = () => {
        window.rescanner.onNFCSuccess = (message) => {
          vm.scanResult(message);
        };
        window.rescanner.onQRSuccess = (message) => {
          vm.scanResult(message);
        };
      };
      document.head.appendChild(rescannerLib);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
