<template>
  <Toolbar :title="job.Vertragsbeschreibung" :showReload="true" @reload="getJob()" backUrl="/jobs" />

  <div class="w-full text-center">
    <div class="w-full md:w-5/6 inline-block text-left">
  <Spinner v-if="loading" />
  
  <div v-if="job.id" style="padding: 10px">
    <div class="detailline">
      <span class="attributename">Position:</span><span class="attributevalue">{{ job.Pos }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Funktion:</span><span class="attributevalue">{{ job.Funktion }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Einsatzort:</span><span class="attributevalue">{{ job.Einsatzort_Beschreibung }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Einsatzstelle:</span><span class="attributevalue">{{ job.Einsatzstelle_Beschreibung }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Datum:</span><span class="attributevalue">{{ job.Datum_von }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Uhrzeit:</span><span class="attributevalue">{{ job.Soll_Von }}-{{ job.Soll_Bis }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Treffpunkt:</span><span class="attributevalue">{{ job.Treffpunkt }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Sonstige Infos:</span><span class="attributevalue">{{ job.Bemerkungen }}</span>
    </div>
    <div style="height: 2px; background: black; width: 100%"></div>
    <div style="
        padding: 15px;
        width: 100%;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
      ">
      <div style="
          padding: 2px;
          margin: 3px;
          font-weight: bold;
          border: 2px solid red;
        " v-if="showError">
        <span>{{ errorMsg }}</span>
      </div>
      <div style="padding: 2px; margin: 3px; font-weight: bold" class="rounded-lg border-4 border-green-500" v-if="showSuccess">
        <span>{{ successMsg }}</span>
      </div>
      <button v-if="job['Zähler_Anzahl'] != 1 && job['Zähler_Anzahl'] != 0" style="width: 100%; border: 2px solid gray; color: gray" disabled>
        Job kann nur in der Hauptansicht angenommen werden
      </button>
      <div v-else>
        <button v-if="showDecline" style="color: red; border: 2px solid red" @click="decline()" class="md:!hidden">
          Absagen
        </button>
        <div class="inline-block md:!hidden" v-if="job.checkinRequired">
          <button id="checkinButton" v-if="!job.checkedIn" @click="checkin()">
            Arbeitsantritt bestätigen
          </button>
        </div>

        <button v-if="showAccept" @click="accept()" class="md:!hidden">Annehmen</button>
        <div v-if="confirmOrDecline" class="md:!hidden">
          <button style="color: red; border: 2px solid red; margin-right: 5px" @click="decline()">
            Absagen
          </button>
          <button :class="{ disabled: confirmDisabled }" style="margin-left: 5px" @click="confirm()">
            Bestätigen
          </button>
        </div>
        <button v-if="job.MA_gebucht && job.current && job.documentsEnabled" @click="window.location='/jobs/'+job.id+'/documents'">Dokumente verwalten</button>
      </div>
    </div>
  </div>
  <div v-else-if="!loading" style="width: 100%; text-align: center; padding: 20px; padding-top: 100px">
    <span style="font-size: 25px; font-weight: bold">Dieser Job ist bereits vergeben oder wurde gelöscht.</span>
  </div></div></div>
</template>

<script>
  import CASP from "../../CASP";
  import Spinner from "@/components/Spinner";
  import Toolbar from "@/components/Toolbar";
  import { useCookies } from "vue3-cookies";
  export default {
    setup() {
      const { cookies } = useCookies();
      return { cookies };
    },
    name: "Jobdetails",
    components: {
      Toolbar,
      Spinner,
    },
    data() {
      return {
        window: window,
        loading: true,
        showAccept: false,
        confirmOrDecline: false,
        showDecline: false,
        showPrivate: true,
        showPublic: false,
        confirmDisabled: false,
        showError: false,
        showSuccess: false,
        errorMsg: "",
        successMsg: "",
        job: [],
        allJobs: [],
        showReload:true,
      };
    },
    methods: {
      getJob() {
        const comp = this;
        this.showAccept = false;
        this.showDecline = false;
        this.confirmOrDecline = false;
        CASP.getJobById(this.token, this.$route.params.jobid, (job) => {
          comp.job = job;
          comp.loading = false;
          if (job.id !== undefined) {
            const dp = job.Datum_von.split(".");
            let startTime = new Date(
              dp[2] + "-" + dp[1] + "-" + dp[0] + "T" + job.Soll_Von + ":00Z"
            );
            startTime = new Date(
              startTime.getTime() + startTime.getTimezoneOffset() * 60000
            );
            const now = new Date();
            console.log(now);
            console.log(startTime);
            if (now.getTime() < startTime.getTime()) {
              if (!job.MA_gebucht) {
                comp.showAccept = true;
              } else if (!job["MA_bestätigt"]) {
                comp.confirmOrDecline = true;
                console.log(startTime.getTime() - now.getTime());
                if (startTime.getTime() - now.getTime() > 24 * 60 * 60 * 1000) {
                  comp.confirmDisabled = true;
                }
              }
            }
            if (job.checkedIn) {
              this.showSuccessMessage(
                "Du hast dich um " + job.checkedIn + " angemeldet."
              );
            }
          }
        });
      },
      back() {
        if (
          this.job["Zähler_Anzahl"] &&
          this.job["Zähler_Anzahl"] != 1 &&
          this.job["Zähler_Anzahl"] != 0
        ) {
          window.location = "/group/" + this.job["Zähler_Anzahl"];
        } else {
          window.location = "/jobs";
        }
      },
      showErrorMessage(message) {
        this.showError = true;
        this.showSuccess = false;
        this.errorMsg = message;
        const comp = this;
        setTimeout(() => {
          comp.showError = false;
        }, 5000);
      },
      showSuccessMessage(message) {
        console.log("showcucessmaessage");
        this.showError = false;
        this.showSuccess = true;
        this.successMsg = message;
      },
      accept() {
        const comp = this;
        CASP.acceptJob(
          this.token,
          this.$route.params.jobid,
          (scode, response) => {
            if (scode === 200) {
              comp.showSuccessMessage("Du hast diesen Auftrag angenommen");
              comp.getJob();
            } else {
              this.showErrorMessage(response);
            }
          }
        );
      },
      checkin() {
        const comp = this;
        CASP.checkInJob(this.token, this.$route.params.jobid, (response) => {
          if (response && response.status == "success") {
            comp.showSuccessMessage(
              "Du hast dich um " + response.time + " angemeldet"
            );
            comp.getJob();
          } else {
            this.showErrorMessage(response.error);
          }
        });
      },
      decline() {
        const comp = this;
        CASP.declineJob(
          this.token,
          this.$route.params.jobid,
          (scode, response) => {
            if (scode === 200) {
              comp.showSuccessMessage("Du hast diesen Auftrag abgesagt");
              comp.getJob();
            } else {
              this.showErrorMessage(response);
            }
          }
        );
      },
      confirm() {
        if (this.confirmDisabled) {
          this.showErrorMessage(
            "Du kannst diesen Auftrag erst 24 Stunden vor Beginn bestätigen"
          );
        } else {
          const comp = this;
          CASP.confirmJob(
            this.token,
            this.$route.params.jobid,
            (scode, response) => {
              console.log(scode);
              if (scode === 200) {
                comp.showSuccessMessage("Du hast diesen Auftrag bestätigt");
                comp.getJob();
              } else {
                this.showErrorMessage(response);
              }
            }
          );
        }
      },
    },
    created: function () {
      document.getElementById("mainspinner").style.display = "none";
      if (this.cookies.get("authtoken")) {
        this.token = this.cookies.get("authtoken");
        this.getJob();
      } else {
        window.location = "/forbidden";
      }
    },
  };
</script>
<style scoped>
  .detailline {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
  }

  .attributename {
    display: inline-block;
    flex-grow: 1;
    flex-basis: 33.33%;
    font-weight: bold;
  }

  .attributevalue {
    border-bottom: 1px solid black;
    flex-grow: 2;
    flex-basis: 66.66%;
  }

  /* Style the buttons that are used to open the tab content */
  button {
    cursor: pointer;
    padding: 10px 14px;
    display: inline-block;
    color: rgb(52, 199, 89);
    border: 2px solid rgb(52, 199, 89);
    border-radius: 8px;
    font-weight: bold;
    font-size: 18px;
    width: 40%;
    background-color: white;
  }

  #checkinButton {
    @apply w-auto;
    @apply border-4;
  }

  .disabled {
    border: 1px solid gray;
    color: gray;
  }
</style>